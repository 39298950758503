import { Cell } from '../grid/Cell'
import { BaseModal } from './BaseModal'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="How to play" isOpen={isOpen} handleClose={handleClose}>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        Guess the name in 6 tries. After each guess, the color of the tiles will
        change to show how close your guess was to the name.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="J"
          status="correct"
        />
        <Cell value="A" />
        <Cell value="N" />
        <Cell value="E" />
        <Cell value="T" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter J is in the name and in the correct spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="D" />
        <Cell value="A" />
        <Cell
          isRevealing={true}
          isCompleted={true}
          value="V"
          status="present"
        />
        <Cell value="I" />
        <Cell value="D" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter V is in the name but in the wrong spot.
      </p>

      <div className="flex justify-center mb-1 mt-4">
        <Cell value="B" />
        <Cell value="O" />
        <Cell value="N" />
        <Cell isRevealing={true} isCompleted={true} value="N" status="absent" />
        <Cell value="Y" />
      </div>
      <p className="text-sm text-gray-500 dark:text-gray-300">
        The letter N is not in the name in any spot.
      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        Any 5-letter name (excluding punctuation) reported in the{' '}
          <a href="https://www.ons.gov.uk/peoplepopulationandcommunity/birthsdeathsandmarriages/livebirths/datasets/babynamesinenglandandwalesfrom1996"
             className="underline font-bold">
              ONS' Baby Names In England and Wales 1996-2020 dataset
          </a>
          {' '}will be accepted. Correct answers are taken from the 2000 most popular names in the same dataset.

      </p>

      <p className="mt-6 italic text-sm text-gray-500 dark:text-gray-300">
        This is based on react-wordle, an open source version of the word guessing game we all know and
        love - check out the original code{' '}
        <a
          href="https://github.com/cwackerfuss/react-wordle"
          className="underline font-bold"
        >
            here
        </a>{' '}
          or the Namedle fork{' '}
          <a
              href="https://github.com/thomsutcliffe/namedle"
              className="underline font-bold"
          >
              here
          </a>{' '}
      </p>
    </BaseModal>
  )
}
